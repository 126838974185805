.bgrid{
    margin: 0;
    padding: 0;
    width: 100%;
    background: #000000;
    background-image: linear-gradient( rgba(33,33,33,1).1em, transparent .1em), linear-gradient(90deg, rgba(33,33,33,1) .1em, transparent .1em);
    background-size: 3em 3em;
    z-index: -10;
    }
    
    
    h1{
        font-size: 7vw !important;
        text-transform: uppercase;

    }


    .header-teemo{
     margin-top: 100px;
     margin-top: 5%;
    }
    .teemo-img {
        position: relative;
        z-index: 1;
        margin-top: -50%;
        width: 50%;
    }
    
    .cta-community {
        padding: 20px;
        border-radius: 7px;
        background-color: #C178FF;
        --bs-btn-border-color: #C178FF;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 700;
        margin: 0;
        color: #ffffff !important;
        z-index: 100 !important;

    }

    .registration-form{
        align-content: space-around !important;
        text-align: left;
        padding: 5% !important;
    }
    
    .stickers{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: clamp(45rem,150vw,100rem);
        margin: 4rem 2.4rem;
    }
    
    .sticker-unite{
        position: absolute;
        z-index: -10;
    }
    
    .stckr-new-friend{
        display: block;
        position: absolute;
        left: 9.486111%;
        top: 34%;
        width: 17%;
    }
    
    .stckr-skins{
        display: block;
        position: absolute;
        right: 12.486111%;
        width: 20%;
        top: 40%;
        transition: all 0ms ease-in var(--stagger);
    
    }
    
    .stckr-wildpass{
        display: block;
        position: absolute;
        right: 12.486111%;
        top: 16%;
        width: 15%;
    
    }
    
    .stckr-tips{
        display: block;
        position: absolute;
        left: 9.486111%;
        top: 14%;
        width: 8%;
    
    }
    
    /*animate line marquee */
    .marquee {
        --duration: 1000s;
        --gap: var(--space);
        display: flex;
        overflow: hidden;
        user-select: none;
        transform: skewY(-1.8deg);
        font-size: 7vw;
        position: relative;
        height: auto;
        margin-top: 13%;
        z-index: 0;
        white-space: nowrap;
    
      }
    
      .marquee2 {
        --duration: 1000s;
        --gap: var(--space);
        display: flex;
        overflow: hidden;
        user-select: none;
        transform: skewY(1.8deg);
        font-size: 7vw;
        position: relative;
        height: auto;
        white-space: nowrap;
    
      }
      
      .bg-filters{
        background-color: #000000 !important;
      }
    
      /*stickers animated*/
    /* Define la animación de temblor */
    @keyframes shake {
        0% { transform: translate(0, 0); }
        25% { transform: translate(-2px, -2px); }
        50% { transform: translate(2px, 2px); }
        75% { transform: translate(-2px, -2px); }
        100% { transform: translate(0, 0); }
    }
    
    /* Aplica la animación a la imagen */
    .imagen-temblor {
        animation: shake 1s; /* Duración de 1 segundo */
    }
    
    .imagen-temblor-2 {
        animation: shake 1s 5s; /* Duración de 1 segundo y retardo de 3 segundos */
    }
    .imagen-temblor-3 {
        animation: shake 1s 3s; /* Duración de 1 segundo y retardo de 3 segundos */
    }
    .imagen-temblor-4 {
        animation: shake 1s 2s; /* Duración de 1 segundo y retardo de 3 segundos */
    }

    
    
    
    @media screen and (min-width: 360px) and (max-width: 1100px) {
        h1{
            font-size: 10vw !important;
        }
        .teemo-img {
            margin-top: -40% !important;
            width: 80%;
        }
        .bgrid{
            margin: 0;
            padding: 0;
            width: 100%;
            background: #000000;
            background-image: linear-gradient( rgba(33,33,33,1).1em, transparent .1em), linear-gradient(90deg, rgba(33,33,33,1) .1em, transparent .1em);
            background-size: 3em 3em;
            z-index: -10;
            }
            
    
      }
      