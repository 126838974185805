.animation-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0.5);
    opacity: 0;
    animation: appearanceAnimation 1.5s ease-out forwards;
  }
  
  .loading-line {
    width: 100%;
    height: 4px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  
  .loading-bar {
    flex: 1;
    height: 100%;
    background: linear-gradient(to right, #9926FC, #F03F42);
    animation: loadingAnimation 2s linear infinite;
  }
  
  @keyframes appearanceAnimation {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  @keyframes loadingAnimation {
    0%, 100% {
      width: 0;
    }
    50% {
      width: 100%;
    }
  }