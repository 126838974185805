/*Reset CSS*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
    color: #fff;
    
	//font-size: 100%;
	/*vertical-align: baseline;*/
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}


body {
	//line-height: 1;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #000000 !important;

}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/*Header home teemo*/

.title_h1{
  line-height: 0.8em !important;
  padding: 3%;
}

.bg-grid-home{
  background-image: linear-gradient( rgba(33,33,33,1).1em, transparent .1em), linear-gradient(90deg, rgba(33,33,33,1) .1em, transparent .1em) !important;
  background-size: 3em 3em;  
  margin-top: -5%;
  z-index: -10;
}
/* marquee div container */
.marquee {
  font-size: 1.9vw;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  height: 3vw;
  overflow: hidden;
  background: linear-gradient(90deg, #F03F42 0%, #9926FC );
  position: relative;
  text-transform: uppercase;

}
/* nested div inside the container */
.marquee div {
  display: block;
  width: 200%;
  position: absolute; 
  overflow: hidden;
  animation: marquee 12s linear infinite;
}
/* span with text */

.marquee span {
  float: left;
  width: 50%;
}
/* keyframe */
@keyframes marquee {
  0% { left: 0; }
  100% { left: -100%; }
}

@keyframes marquee {
  0% { left: 0; }
  100% { left: -100%; }
}


.row>* {
  padding-right: 0;
  padding-left:0;
}

.hs {
  overflow-x: hidden;
}

/* TABS */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Passion+One&display=swap');

body {
  font-family: 'Open Sans', sans-serif;
    background-color: #2C2C2C;
    color: white;
  }
  
  h1 {
    font-family: 'Passion One', sans-serif;
    color: #ffff;
    font-size: 7vw !important;


  }

  h2{
    font-size: 55px !important;
    font-family: 'Passion One', sans-serif;
    line-height: 110%;

  }

  h3{
    font-size: 40px !important;
    font-family: 'Passion One', sans-serif;
  }

  h4{
    font-size: 25px !important;
    font-family: 'Passion One', sans-serif;
  }
  p{
    font-size: 16px;
    line-height: 170%;
  }
  
  li{
    font-size: 16px;
    line-height: 170%;
  }

  
  .p-footer{
    font-size: 12px;
  }

.modal-content{
  background-color: #2C2C2C !important;
}

a{
  font-weight: 600 !important;
}

  a:hover{
    color: #fff !important;
    text-decoration: none !important;
  }

  .btn-community {

    //border-radius:7px !important;
    filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.5)) !important;
    background: rgba(44, 44, 44, 0.4) !important;
    font-family: 'Open Sans', sans-serif !important;
    font-size: 16px !important;
    text-transform: uppercase !important;
    font-weight: 700 !important;
    --bs-btn-border-color: #110e0e !important;
    color: #ffffff !important;
    height: 45px !important;
      align-content: center !important;

    }

    

    .btn-community-sus {
      background-color: #9926FC !important;
      font-family: 'Open Sans', sans-serif !important;
      font-size: 16px !important;
      text-transform: uppercase !important;
      font-weight: 700 !important;
      --bs-btn-border-color: #110e0e !important;
      padding-left: 20px !important;
      padding-right: 20px !important;
      border-color: #000 !important;
      color: #ffffff !important;
      margin-right: 20px !important;
      will-change: transform !important;
      transition: transform 450ms !important;
      font-weight: 700 !important;
      height: 45px !important;
      align-content: center !important;
      }


      .btn-community-sus:hover:before {
        transition: transform 125ms !important;
        transform: translateY(-3px) !important;
        content: '';
        background: linear-gradient(90deg, #F03F42 0%, #9926FC );
        position: absolute;
        top: -2px;
        left:-2px;
        background-size: 400%;
        z-index: -1;
        filter: blur(5px);
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        animation: glowing 20s linear infinite;
        opacity: 0;
        transition: opacity .3s ease-in-out;
        border-radius: 7px;
      }
      


    .discord-icon {
      width: 20px;
      margin: 5px;
    }

    .social-icon{
      width: 25px;
      vertical-align: middle;
      align-items: center;
    }
    .social-iconf{
      height: 25px;
    }

    .glow-on-hover{
      display: flex;
      border: none;
      outline: none;
      color: #fff !important;
      cursor: pointer;
      position: relative;
      z-index: 0;
      border-radius: 7px;
  }
  
  .glow-on-hover:before {
      content: '';
      background: linear-gradient(90deg, #F03F42 0%, #9926FC );
      position: absolute;
      top: -2px;
      left:-2px;
      background-size: 400%;
      z-index: -1;
      filter: blur(5px);
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      animation: glowing 20s linear infinite;
      opacity: 0;
      transition: opacity .3s ease-in-out;
      border-radius: 7px;
      color: #ffffff !important;
  }
  
  .glow-on-hover:active {
      color: #000
  }
  
  .glow-on-hover:active:after {
      background-color: transparent !important;
  }
  
  .glow-on-hover:hover:before {
      opacity: 1;
  }
  
  .glow-on-hover:after {
      z-index: -1;

      position: absolute;
      width: 100%;
      height: 100%;
      background: #111;
      left: 0;
      top: 0;
      border-radius: 7px;
  }

  /*timing*/

  .dataNumber {
    text-align: center;
    align-items: center;
    font-size: 75px !important;
    font-family: 'Passion One', sans-serif;
    line-height: 110%;
    position: translateX(-50%) translateY(-50%);
    font-size: 5rem;
    background-image: linear-gradient(45deg, #FF0202 0%, #FF0202 35%, #9926FC 66%, #9926FC 100%);
    background-clip: text; /* Propiedad estándar para aplicar el fondo solo al área del texto */
    -webkit-text-fill-color: transparent;
    animation: glow 5s linear infinite;
    background-size: 200%;
  }
  
  

  .gradientfont {
    transform: translateX(-50%) translateY(-50%);
    background-image: linear-gradient(45deg, #FF0202 0%, #FF0202 35%, #9926FC 66%, #9926FC 100%);
    background-clip: text; /* Aplicar el fondo solo al área del texto */
    color: transparent; /* Hacer el texto transparente */
    animation: glow 5s linear infinite;
    background-size: 200%;
  }
  
  
  @keyframes glow {
    0% {
      background-position: 0% 43%;
    }
    50% {
      background-position: 100% 58%;
    }
    100% {
      background-position: 0% 43%;
    }
  }
  
  
  .bglaunch{
    background-color: #000;
  }

  

  @keyframes glowing {
      0% { background-position: 0 0; }
      50% { background-position: 400% 0; }
      100% { background-position: 0 0; }
  }




  a {
      color: #e9ecef;
      text-decoration: underline;
      padding-left: 10px;
      padding-right: 10px;
  }

  .label-torneos{
    color: #828F9C;
    font-size: 12px;
    text-align: left;
    line-height: 100%;

  }

  .content-torneos{
    color: #ffffff;
    font-size: 16px;
    text-align: left;
    line-height: 180%;
  }

  .rules{
    font-size: 12px;
    text-align: left;
    font-weight: 300;
    line-height: 180%;
    color: #828F9C;

  }
  
  
.tag-torneos{
  color: #ffffff;
  font-size: 14px;
  text-align: left;
  line-height: 100%;
  background-image: linear-gradient(20deg, #FF0202 0%, #FF0202 35%, #9926FC 66%, #9926FC 100%);
  max-width: fit-content;
  padding: 10px;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  }

  .sub-section-heading{
    letter-spacing: 10px;
    font-size: 12px !important;
  }
  
  .nav-link {
    color: #828F9C !important;
    border: none !important;
}
  .navbar-nav .nav-link.active, .navbar-nav .show>.nav-link {
    color: #e9ecef !important;
    font-weight: bold;
    border: none !important;

    background-color: transparent !important;
    background-image: linear-gradient(-45deg, #FF0202 0%, #FF0202 35%, #9926FC 66%, #9926FC 100%);
    background-size: 100% 3px; /* Ancho y grosor de la línea inferior */
    background-repeat: no-repeat;
    background-position: 0 100%; /* Posición de la línea inferior */ 
}
.navbar-collapse {
  flex-grow: 0;
}

.nav-tabs .nav-link.active{
  background-color: transparent !important;
  border-color: none !important;
  color: #ffffff !important;
  text-decoration: none !important;
  border: none !important;

}

.nav-tabs{
  color: #828F9C !important;
  border: none !important;
  border-bottom: border 1px solid #9926FC !important;
  
}

.nav-link.active{
  border-color: none !important;
  border: none !important;
  font-weight: 700;
  background-color: transparent !important;
  background-image: linear-gradient(-45deg, #FF0202 0%, #FF0202 35%, #9926FC 66%, #9926FC 100%);
  background-size: 100% 3px;
  background-repeat: no-repeat;
  background-position: 0 100%;
}
.nav-link:hover{
  border-color: none !important;
  border: none !important;
  font-weight: 700;
}

.nav-tabs.active{
  border-color: none !important;
  border: none !important;
  font-weight: 700;
  border-bottom: border 1px solid #9926FC !important;
  
}

.logo{
  width: 101px;
}

.title-header{
  margin-top: 10%;
}

.p-l-r{
  padding-left: 3%; 
  padding-right: 3%;
}



.card-news {
  background: #111;
  border-top:  groove;
  border-radius: 6px;
  border-color: #4e00c2;

  padding: 20px;
  cursor: pointer;

}



.card-news:hover {
  transform: translateX(-50%) translateY(-50%);
  background-image: linear-gradient(45deg, #FF0202 0%, #FF0202 35%, #9926FC 66%, #9926FC 100%);
  background-clip: text; /* Aplicar el fondo solo al área del texto */
  color: transparent; /* Hacer el texto transparente */
  animation: glow 5s linear infinite;
  background-size: 200%;
}



.card-news:hover:before, .card:hover:after {
  animation: none;
  opacity: 0;
}


.card-news::before {
  content: "";
  width: 104%;
  height: 102%;
  border-radius: 8px;
  
    position: absolute;
    z-index: -1;
    top: -1%;
    left: -2%;
    animation: spin 2.5s linear infinite;
}

.card-news::after {
  position: absolute;
  top: calc(var(--card-news-height) / 6);
  left: 0;
  right: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  transform: scale(0.8);
  filter: blur(calc(var(--card-news-height) / 6));
  background-image: linear-gradient(
    var(--rotate)
    , #5ddcff, #3c67e3 43%, #4e00c2);
    opacity: 1;
  transition: opacity .5s;
  animation: spin 2.5s linear infinite;
}



@keyframes spin {
  0% {
    --rotate: 0deg;
  }
  100% {
    --rotate: 360deg;
  }
}


.card-body{
  --bs-card-bg: #1A1A1A;
}

.card:hover{
  transform: scale(1.01);
  transition: 0.3s;

}
.card-img-top:hover{
  transform: scale(1.01);
  transition: 0.3s;

}

.card-title{
  margin-bottom: 16px;
}
/*Header Home*/
.div-section{
  width: 100%;
    max-width: max-content;
    margin-left: auto;
    margin-right: auto;
}

.h-section{
    margin: 0;
    padding: 0;
   background-color: #000; 

}

.h-section-sorteo{
  margin: 0;
  padding: 0;
 background-color: #1A1A1A; 

}
.section-black{
  background-color: #000;
  vertical-align: middle;

}

.social-section{
  margin-top: 50px;
  margin-bottom: 50px;
}

.content-card{
  width: auto !important;
  height: 142px;
  margin: 10px;
  border-radius: 7px;
}

/*Nav*/

.nav-link {
  transition: all 0.2s;
}

.nav-link:hover {
  //text-decoration: underline !important;
  //border-bottom: 2px solid #F03F42;
  color: #F03F42;
}





  /*TOURNAMENT SECTION*/

  .img-tournament{
   // background-image: url("/image/teemo.svg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .bg-tournament-lp{
   // background-image: url("/image/bg-tournament-lp.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .align-text-v-lp{
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .section-tournament{
    display: flex;
    max-width: fit-content;
    align-items: center;
  }

  .card-tournament{
    background-color: #191919;
  }
 
  .form-control{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .register-tournament-section-bg-color{
   // background: url("/image/section-bg-form.png") no-repeat, linear-gradient(90deg, #F03F42 0%, #9926FC 100%);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
  }
  .img-form-tournament{
    width: 100%;
    max-width: 520px;
  }

    /*SORTEO SECTION*/

    .img-sorteo{
     // background-image: url("/image/sorteo-cetus-wildrift-lanzamiento-comunidad-ganador.jpg")no-repeat, linear-gradient(90deg, #F03F42 0%, #9926FC 100%);
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: center;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    .bg-sorteo-lp{
      //background-image: url("/image/sorteo-cetus-wildrift-lanzamiento-comunidad-ganador.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      margin-left: auto;
      margin-right: auto;
      position: absolute;
      display: block;
      z-index: -10;
      }
/*index*/
.bracket-container {
  display: flex;
  flex-wrap: wrap;
}

.bracket {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  width: 200px;
}

.card-header {
  background-color: #000 !important;
  color: #fff !important;
  border: none !important;
  font-size: 12px !important;
  flex-wrap: nowrap !important;
}

.card-body {
 // background-color: #111 !important;
  color: #fff !important;
  //border: none !important;
  font-size: 10px !important;
  border-radius: 8px;
  margin: 0 !important;
}

.body-card-key{
 background-color: #ffffff;
 border-radius: 30px !important;
 margin: 20px;
 
}

.card-footer {
  background-color: #9926FC !important;
  color: #fff !important;
  border: none !important;
  font-size: 12px !important;
}

.card{
  border: 0 none  !important;
  margin-right: 3% !important;
  padding-right: 3% !important;
  width: 220px;
  background-color: transparent !important;
}

.p-card-llaves{
  text-align: left;
  padding-left: 5% !important;
  font-size: 14px !important;
 color: #000;
 margin-bottom: 0 !important;

}

.p-card-check{
  text-align: right !important;
  padding-right: 15% !important;
  font-size: 14px;
  margin-bottom: 0 !important;
}


.img-index-header{
  //background-image: url("/image/teemo.svg");

  width: 100%;
  margin-left: auto;
  margin-right: auto;
}


  /*ABOUT US*/
  
  .img-about-us{
    background:  linear-gradient(90deg, #F03F42 0%, #9926FC 100%);
    background-repeat: no-repeat;
    background-size: cover;

  }

  /*Api*/
  .img-api{
    border-radius: 44px;
    border: 2px solid #C89B3C;
    width: 44px;
    height: 44px;
    flex-shrink: 0;
     }


     .text-api{
      color: #FFF;
      text-align: left;
      font-family: Open Sans;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      text-transform: capitalize;
      margin-bottom: 0px;
      max-width: 8ch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
     }

     .subheding-api{
      color: #898C8B;
      text-align: left;
      font-family: Open Sans;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      text-transform: capitalize;
      margin-bottom: 0px;
      
     }

     .div-api{
     padding-left: 5px;

     }

     .container-api{
      height: auto;
      padding: 5%;
     }


     .version-tag{
      color: #FFF !important;
      font-family: Open Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.5px;
      border-radius: 0px;
      background: rgba(44, 44, 44, 0.40) !important;
      border-bottom: none !important;
      
     }

     .title-version{
      color: #FFF;
      font-family: Open Sans;
      font-size: 36px !important;
      font-style: normal;
      font-weight: 800;
      line-height: 75px; /* 241.935% */
      letter-spacing: 0.5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-shrink: 0;
      text-align: left;
      z-index: 1;
     }

     .tag-parche {
      position: relative;
      background: linear-gradient(90deg, #9926FC, #A621DB, #FF0202) !important;
      color: #fff;
      padding: 5px 10px;
      overflow: hidden;
      z-index: 2;
      transition: transform 125ms !important; 
    }

    .badgec{
      background: linear-gradient(90deg, #9926FC, #A621DB, #FF0202) !important;
    }
    
    .tag-parche::before {
      content: "";
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, transparent, #ea02ffb0, transparent) !important;
      animation: slide 3s linear infinite !important;
      z-index: 1;
    }
    
    @keyframes slide {
      0% {
        left: 100%;
      }
      100% {
        left: -100%;
      }
    }


     .p-champ{
      color: #464853;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
      letter-spacing: 0.5px;
      text-align: left;
     }

     .nav-champs, .nav-link-champs.active{
      height: 45px;
      gap: 8px;
      flex-shrink: 0;
      border-radius: 0px 4px 4px 0px;
      border: 1px solid #9926FC !important;
      background: rgba(44, 44, 44, 0.40) !important;
     }

     .nav-link-champs{
      border: 1px solid #464853 !important;
      border-radius: 0px !important;
     }
     .img-icon-search{
       max-width: 22px;
     }

     .padding{
      padding-bottom: 2%;
     }


     .padding-b-t{
      padding-top: 4%;
      padding-bottom: 4%;
     }


     .c-style{
      justify-content: 'flex-start' !important;
      display: flex !important;
     }

     .table {
      width: 100%;
      border-collapse: collapse;
      background-color: transparent !important;
    }


    .tier-column {

      justify-content: space-around;
    }

    .cont{
      padding: 0 !important;
    }

    .img-lines{
      max-width: 22px !important;
    }
   

    .search-bg{
      background-color: #2C2C2C66 !important;
      color: white !important;
      border: 0 !important;
      border-radius: 5px !important;

    }
    .search-bg::placeholder {
      color: #7F7F7F !important;
    }

    .champ-list{
      border-radius: 8px;
      border: 1px solid #464853;
      background: rgba(44, 44, 44, 0.40);
    }
    

  /*Footer*/
  .footer {
    padding-top: 5%;
    padding-bottom: 70px;
    background-color: black;
    color: #6C6F71;
    margin-top: auto; /* Empuja el pie de página hacia la parte inferior */
  }


  .overlayer{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text-spacing{
    font-size: 16px !important;
    letter-spacing: 5px!important;
    line-height: 120% !important;
    text-transform: capitalize !important;
    margin-top: 5%;
  }

  .min-line{
      line-height: 90%;
  }

  .titles-torneos{
    text-transform: uppercase;
    line-height: 100%;
  }

.bg-header-top{
  background-color: #191919 !important;
}
  .text-spacing-2{
    font-size: 12px !important;
    letter-spacing: 2px!important;
    line-height: 120% !important;
    font-weight: 600;
    text-transform: capitalize !important;
  }

  .accordion-item{
    border: none !important;
    border-bottom: 1px solid #6C6F71 !important;
    padding-bottom: 1px;
  }

  .accordion-item:first-of-type .accordion-button {
    border: none !important;
    background-color: #191919 !important;
    color: white !important;
    font-weight: 400 !important;
  }

  .accordion-header{
    border-radius: 0 !important;
    background-color: #191919 !important;
    border: none !important;
    border-style: none !important;
    border-color: transparent !important;
    font-weight: 400 !important;

      border-bottom: none !important;
  }
  .accordion-body{
    background-color: #191919 !important;
    border: none !important;

  }

  .accordion-button{
    background-color: transparent !important;
    //border-bottom: #ffffff !important;
    color: #ffffff !important;
  }

  .know-more{
    color: white !important;
    letter-spacing: 2px!important;
    text-transform: uppercase;
    text-decoration: none !important;
    font-size: 16px;
  }

  .bg-menu{
    background: linear-gradient(90deg, #F03F42 0%, #9926FC );
  }

  .bg-modal{
    background-color: #111 !important;
  }
  .header-t-section {
    padding-bottom: 6% !important;
    position: relative;
    background-size: cover; 
    background-position: center; 
    color: white; 

  }

  .toast-header{
    background-color: #1A1A1A !important;
    color: white !important;
  }

  .toast{
    background-color: #191919 !important;
    color: white !important;
    text-align: left !important;
  }


@keyframes gradientAnim {
    to {
    background-position: 100% top;
    }
  }
  

  /*Media Queries*/



  @media (max-width: 1400px) {
    .title-header{
      margin-top: 70px;
      margin-bottom: 30px;
  
  }
    .container {
      max-width: 960px;
    }
    .col-6 {
      flex: 0 0 auto;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
  .h-section{
    background-color: #000000; 
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    margin: 0 ;
    
  }

  .h-section-sorteo {
    width: 100%;
    --opacidad-negro:0.5;
    background-position: unset;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: auto;
    margin-right: auto;
}

    .img-tournament{
      display: none;
    }
    .img-sorteo{
      display: none;
    }

    .img-index-header{
      display: none;
    }

    .h-section-header {
      width: 100%;
      --opacidad-negro:0.5;
      //background:transparent;
      background-position: unset;


      margin-left: auto;
      margin-right: auto;
  }



  
    .hsection {
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      margin-left: auto;
      margin-right: auto;
  }

  @media (max-width: 768px) {
    .container {
      max-width: 720px;
    }

  }
  
  @media (max-width: 576px) {
    .title-header{
      margin-top: 70px;
      margin-bottom: 30px;
  
  }
    .h-section-sorteo {
      width: 100%;
      --opacidad-negro:0.5;
      //background-image: linear-gradient(rgba(0, 0, 0, var(--opacidad-negro)), rgba(0, 0, 0, var(--opacidad-negro))), url(/image/sorteo-cetus-wildrift-lanzamiento-comunidad-ganador.jpg);
      background-position: unset;
      background-repeat: no-repeat;
      background-size: cover;
      margin-left: auto;
      margin-right: auto;
  }

    .marquee {
      font-size: 7.9vw;
      color: #fff;
      font-family: 'Open Sans', sans-serif;
      height: 5vw !important;
      overflow: hidden;
      background: linear-gradient(90deg, #F03F42 0%, #9926FC );
      position: relative;
      text-transform: uppercase;
  }


    h2 {
      font-size: 61px !important;
      font-family: 'Passion One', sans-serif;
      line-height: 110%;
    }
    .container {
      max-width: 540px;
    }
    .img-form-tournament{
      display: none;
    }

    .img-sorteo{
      display: none;
    }

    .form-tournament{
      max-width: 360px;
      margin-left: auto;
      display: flex;
    }

    .img-jinx-resp{
    max-width: 320px !important;
    }
    .bg-sorteo-lp{
display: none;
    }
    .header-t-section{
      background-color: #4e00c210;
    }

  }}
  
  